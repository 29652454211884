import '../styles/globals.css';
import { useEffect, useState } from 'react';

import type { AppProps } from 'next/app';
import { SWRConfig } from 'swr';

import Spinner from '@/components/common/Spinner';
import { AppContext } from '@/lib/context/appContext';
import { exitValidationModule } from '@/lib/helpers/exitValidiationModule';
import useStorage from '@/lib/hooks/useStorage';
import { ExitValidationEnum } from '@/lib/models/exitValidationEnum';

function MyApp({ Component, pageProps }: AppProps) {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState('Validierung');
  const [right, setRight] = useState(false);
  const { getItem, setItem } = useStorage();

  const addExistingToken = async () => {
    if (!token) {
      const jwt = getItem('jwt', 'session');
      if (jwt) {
        setToken(jwt);
      }
    }
    if (!token && process.env.NEXT_PUBLIC_JWT_TOKEN) {
      setToken(process.env.NEXT_PUBLIC_JWT_TOKEN);
    }
  };

  useEffect(() => {
    const getJwtToken = (token: string): boolean => {
      setItem('jwt', token, 'session');
      setToken(token);
      return true;
    };
    window.getJwtToken = getJwtToken;
    addExistingToken();
  }, []);

  if (!token) {
    return <Spinner></Spinner>;
  }
  return (
    <SWRConfig
      value={{
        fetcher: async (resource) => {
          const res = await fetch(resource, {
            headers: { Authorization: 'Bearer ' + token },
          });

          if (!res.ok) {
            throw new Error('An error occurred while fetching the data.');
          }

          return res.json();
        },
        onError: (error) => {
          if (error.status !== 403 && error.status !== 404) {
            exitValidationModule(ExitValidationEnum.ERROR);
          }
        },
      }}
    >
      <AppContext.Provider value={{ title, setTitle, right, setRight, token }}>
        <Component {...pageProps} />
      </AppContext.Provider>
    </SWRConfig>
  );
}

export default MyApp;
